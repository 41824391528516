@-webkit-keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(211, 191, 255, 0.9);
  }
  100% {
    background-color: transparent;
  }
}

.flash {
  -webkit-animation-name: flash;
  -webkit-animation-duration: 900ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
}

.transparent {
  background-color: transparent;
}
