.react-calendar {
  width: 350px;
  max-width: 100%;
  background: transparent;
  border: none;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  color: #2d2c2e;
  font-family: 'Open Sans';
  font-size: 15px;
}
.react-calendar__navigation button {
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: transparent;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  color: #7a728c;
  font-size: 14px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 5px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #000000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #cdc9d4;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 6px;
  background: none;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: radial-gradient(
    circle at center 44%,
    #e6e6e6 0%,
    #e6e6e6 50%,
    #e6e6e6 50%,
    transparent 50%,
    transparent 50%
  );
}
.react-calendar__tile--range {
  color: #714cc5;
  font-weight: bold;
}
.react-calendar__tile--now:enabled:hover
  .react-calendar__tile--now:enabled:focus {
  background: radial-gradient(
    circle at center 44%,
    #e6e6e6 0%,
    #e6e6e6 50%,
    #e6e6e6 50%,
    transparent 50%,
    transparent 50%
  );
}
.react-calendar__tile--hasActive {
  background: #714cc5;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #714cc5;
}
.react-calendar__tile--active {
  color: white;
  background: radial-gradient(
    circle at center 44%,
    #714cc5 0%,
    #714cc5 50%,
    #714cc5 50%,
    transparent 50%,
    transparent 50%
  );
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: radial-gradient(
    circle at center 44%,
    #714cc5 0%,
    #714cc5 50%,
    #714cc5 50%,
    transparent 50%,
    transparent 50%
  );
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background: radial-gradient(
    circle at center 44%,
    #e6e6e6 0%,
    #e6e6e6 50%,
    #e6e6e6 50%,
    transparent 50%,
    transparent 50%
  );
}
