@import '@/node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-documenteditor/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-base/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '@/node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css';

/* Used for docs structure */
:root {
  --docs-top-nav-height: 51px;
}

.sentry-error-embed-wrapper .powered-by {
  display: none;
}

/* Manage scrolling for smart tag list in document editor */

#smart-tags-menu-list {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

#smart-tags-menu-list::-webkit-scrollbar {
  /* Webkit (Chrome) */
  width: 0;
  height: 0;
}

/* Set z index for google maps address search so that it appears in dialog boxes
    (default z-index for dialog boxes is 1300  */
div.pac-container.pac-logo {
  z-index: 10000 !important;
}

.markdoc-image {
  max-width: 100%;
}

.markdocContainer table,
.markdocContainer th,
.markdocContainer td {
  border: gray 1px solid;
}

.markdocContainer table {
  border-collapse: collapse;
}

.markdocContainer th,
.markdocContainer td {
  padding: 0px 20px;
}
