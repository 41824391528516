@media (max-width: 959px) {
  .submitFeedback {
    display: none !important;
  }

  .navigation {
    display: none !important;
  }

  #menuButton {
    color: #714cc5;
  }
}

@media (min-width: 960px) {
  #menuButton {
    display: none;
  }

  #menuLinks .menuLink {
    display: inline-block;
  }
}
