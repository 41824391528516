body {
  margin: 0;
  height: 100%;
}

#__next,
#root,
html {
  background-color: #f0f0f7;
  background-image: url('/assets/images/Background.png');
  height: 100%;
  overflow: hidden;
  background-size: 100%;
}
